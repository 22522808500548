$client1: #9ff1d7;
$client2: #23234f;
$client3: #706f6f;

.leaflet-popup-content-wrapper,
.leaflet-popup.tip {
    background-color: $client2;
    color: $client1;
}

:export {
    client1: $client1;
    client2: $client2;
    client3: $client3;
}
