/* Auth */

.auth {
    height: 100%;
    background: url('../../../images/background-cropped.png');
    .lock-profile-img {
        width: 90px;
        height: 90px;
        border-radius: 100%;
    }
    .auth-form-light {
        background: $white;
        select {
            color: $input-placeholder-color;
        }
        .input-group {
            .form-control {
                &:focus,
                &:active {
                    border-color: $border-color;
                }
            }
        }
    }
    .auth-form-transparent {
        background: transparent;
        .form-control,
        .input-group-text {
            border-color: theme-color(secondary);
            &:focus,
            &:active {
                border-color: theme-color(secondary);
            }
        }
        select {
            outline-color: theme-color(secondary);
        }
    }
    &.auth-img-bg {
        padding: 0;
        .auth-form-transparent {
            @media (min-width: 768px) {
                width: 55%;
                margin: auto;
            }
        }
    }
    .brand-logo {
        //margin-bottom: 2rem;
        img {
            width: 150px;
        }
    }
    .alignCenter {
        text-align: center;
        margin-bottom: 2rem;
        color: black;
        font-weight: bold;
    }
    form {
        .form-group {
            margin-bottom: 1.5rem;
            label {
                font-size: 0.8125rem;
            }
            .form-control {
                background: transparent;
                border-radius: 0;
                font-size: 0.9375rem;
            }
        }
        .auth-form-btn {
            padding: 1rem 3rem;
            line-height: 1.5;
            color: theme-color(client1);
            background-color: theme-color(client2);
            text-transform: none;
        }
        .text-client {
            color: theme-color(client2);
        }
        .auth-link {
            font-size: $default-font-size;
            cursor: pointer;
            &:hover {
                color: initial;
            }
        }
    }
}
