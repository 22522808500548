.dark-theme {
    font-family: sans-serif;
    background: #333;
    color: #eee;
}

.light-theme {
    font-family: sans-serif;
    color: #333;
    background: gray;
}
