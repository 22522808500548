/* Pixel loader */
.pixel-loader {
    width: 10px;
    height: 10px;
    background: theme-color(warning);
    color: theme-color(warning);
    margin: 80px auto;
    box-shadow:
        15px 15px 0 0,
        -15px -15px 0 0,
        15px -15px 0 0,
        -15px 15px 0 0,
        0 15px 0 0,
        15px 0 0 0,
        -15px 0 0 0,
        0 -15px 0 0;
    animation: anim 2s linear infinite;
}

@keyframes anim {
    0% {
        -webkit-filter: hue-rotate(0deg);
    }
    50% {
        box-shadow:
            20px 20px 0 0,
            -20px -20px 0 0,
            20px -20px 0 0,
            -20px 20px 0 0,
            0 10px 0 0,
            10px 0 0 0,
            -10px 0 0 0,
            0 -10px 0 0;
    }

    75% {
        box-shadow:
            20px 20px 0 0,
            -20px -20px 0 0,
            20px -20px 0 0,
            -20px 20px 0 0,
            0 10px 0 0,
            10px 0 0 0,
            -10px 0 0 0,
            0 -10px 0 0;
    }

    100% {
        transform: rotate(360deg);
        -webkit-filter: hue-rotate(360deg);
    }
}
