.sidebar {
    min-height: calc(100vh - #{$navbar-height});
    background-image: url('../images/aside.png');
    background-position: 0% -64px;
    font-family: $type1;
    font-weight: 600;
    padding: 0;
    width: $sidebar-width-lg;
    z-index: 11;
    transition:
        width $action-transition-duration $action-transition-timing-function,
        background $action-transition-duration
            $action-transition-timing-function;
    -webkit-transition:
        width $action-transition-duration $action-transition-timing-function,
        background $action-transition-duration
            $action-transition-timing-function;
    -moz-transition:
        width $action-transition-duration $action-transition-timing-function,
        background $action-transition-duration
            $action-transition-timing-function;
    -ms-transition:
        width $action-transition-duration $action-transition-timing-function,
        background $action-transition-duration
            $action-transition-timing-function;

    .nav {
        overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;
        margin-bottom: 60px;

        .nav-item {
            padding: 0 $sidebar-menu-padding-x;
            @include transition-duration(0.25s);
            transition-property: background;
            -webkit-transition-property: background;
            position: relative;
            .ReactCollapse--collapse {
                -webkit-transition: height $action-transition-duration
                    $action-transition-timing-function;
                -moz-transition: height $action-transition-duration
                    $action-transition-timing-function;
                -ms-transition: height $action-transition-duration
                    $action-transition-timing-function;
                transition: height $action-transition-duration
                    $action-transition-timing-function;
            }

            .nav-link {
                @include display-flex;
                @include align-items(center);
                white-space: nowrap;
                padding: $sidebar-menu-padding-y 0 $sidebar-menu-padding-y 0;
                color: $sidebar-dark-menu-color;
                @include transition-duration(0.45s);
                transition-property: color;
                -webkit-transition-property: color;
                cursor: pointer;
                .icon-bg {
                    margin-right: 0.875rem;
                    // background: $sidebar-dark-icon-bg;
                    height: 24px;
                    width: 24px;
                    position: relative;
                    z-index: 2;
                    @include display-flex;
                    @include align-items(center);
                    @include justify-content(center);
                    @include border-radius(3px);
                    text-align: center;
                    .rtl & {
                        margin-left: 1rem;
                        margin-right: 0;
                    }
                    .menu-icon {
                        color: $sidebar-dark-icon-color;
                        font-size: 24px;
                        &:after,
                        &:before {
                            color: inherit;
                        }
                    }
                }

                i {
                    color: inherit;

                    &.menu-icon {
                        font-size: $sidebar-icon-font-size;
                        line-height: 1;
                        margin-right: 0;
                        .rtl & {
                            margin-left: 0;
                            margin-right: 0;
                        }
                        color: $sidebar-dark-menu-icon-color;
                        &:before {
                            vertical-align: middle;
                        }
                    }

                    &.menu-arrow {
                        font: normal normal normal 24px/1
                            'Material Design Icons';
                        line-height: 1;
                        font-size: $sidebar-icon-font-size;
                        margin-left: auto;
                        .rtl & {
                            margin-left: 0;
                            margin-right: auto;
                        }
                        color: $sidebar-dark-menu-arrow-color;
                        &:before {
                            content: '\f142';
                            font-size: inherit;
                            color: inherit;
                            .rtl & {
                                content: '\f141';
                            }
                        }
                        + .menu-icon {
                            margin-left: 0.25rem;
                            .rtl & {
                                margin-left: 0;
                                margin-right: 0.25rem;
                            }
                        }
                    }
                }

                .menu-title {
                    color: inherit;
                    display: inline-block;
                    font-size: $sidebar-menu-font-size;
                    line-height: 1;
                    vertical-align: middle;
                }

                .badge {
                    margin-right: auto;
                    margin-left: 1rem;
                }

                &[aria-expanded='true'] {
                    .menu-arrow {
                        &:before {
                            content: '\f140';
                        }
                    }
                }
            }

            &.active {
                background: $sidebar-dark-menu-active-bg;

                > .nav-link {
                    background-color: theme-color(client1);
                    height: 42px;
                    margin-left: -38px;
                    margin-right: -38px;
                    // &:before {
                    //   content: "";
                    //   position: absolute;
                    //   left: 0;
                    //   top: 0.5rem;
                    //   bottom: 0;
                    //   background: theme-color(client1);
                    //   height: 24px;
                    //   width: 4px;
                    //   .rtl & {
                    //     left: auto;
                    //     right: 0;
                    //   }
                    // }
                    .menu-title {
                        color: theme-color(client2);
                        margin-left: 38px;
                        font-family: $type1;
                        font-weight: 600;
                    }
                    .menu-icon {
                        color: theme-color(client2);
                        margin-left: 72px;
                        &:after,
                        &:before {
                            font-size: 24px;
                            color: inherit;
                        }
                    }
                    i {
                        color: theme-color(client2);
                    }
                }
            }
            &:hover {
                background: $sidebar-dark-menu-hover-bg;
            }
            &.nav-profile {
                .nav-link {
                    height: auto;
                    line-height: 1;
                    border-top: 0;
                    padding: 1.25rem 0;
                    .nav-profile-image {
                        width: 44px;
                        height: 44px;
                        img {
                            width: 44px;
                            height: 44px;
                            border-radius: 100%;
                        }
                    }
                    .nav-profile-text {
                        margin-left: 1rem;
                        .rtl & {
                            margin-left: auto;
                            margin-right: 1rem;
                        }
                    }
                    .nav-profile-badge {
                        font-size: 1.125rem;
                        margin-left: auto;
                        .rtl & {
                            margin-left: 0;
                            margin-right: auto;
                        }
                    }
                }
            }
            &.sidebar-actions {
                margin-top: 1rem;
                .nav-link {
                    border-top: 0;
                    display: block;
                    height: auto;
                }
                &:hover {
                    background: initial;
                    .nav-link {
                        color: initial;
                    }
                }
            }

            &.nav-category {
                color: theme-color(dark);
                font-size: 0.8125rem;
                line-height: 16px;
                margin: 25px 0px 10px 0px;
                color: $sidebar-dark-menu-icon-color;
                font-family: $type1;
                font-weight: 700;

                .nav-link {
                    padding: 1.2rem 2rem 0.2rem;

                    &:hover {
                        color: theme-color(dark);
                    }
                }
            }
            &.documentation-link {
                margin: 2.5rem 0;

                .nav-link {
                    background: theme-color(primary);
                    color: $white;
                    @include border-radius(2px);
                    padding: 0.875rem 0;
                    font-size: 1.125rem;
                    font-weight: bold;

                    .icon-bg {
                        display: none;
                    }

                    .menu-title {
                        margin: auto;
                        background: transparent;
                        color: $white;
                    }
                }
            }
            &.sidebar-user-actions {
                padding-left: 0;
                padding-right: 0;
                .user-details {
                    padding: 1rem 2.375rem;
                    width: 100%;
                    border-top: 1px solid $sidebar-dark-action-border;
                    border-bottom: 1px solid $sidebar-dark-action-border;
                    margin-bottom: 20px;
                    .sidebar-profile-img {
                        width: 31px;
                        height: 31px;
                        border-radius: 100%;
                        margin-right: 12px;
                        .rtl & {
                            margin-left: 1rem;
                            margin-right: 0;
                        }
                    }
                    .sidebar-profile-text {
                        color: $white;
                    }
                    .badge {
                        margin-right: 0;
                    }
                }
                .sidebar-user-menu {
                    padding: 0 2.375rem;
                    .nav-link {
                        font-size: 15px;
                        i {
                            font-size: 18px;
                            margin-right: 12px;
                            .rtl & {
                                margin-right: 0;
                                margin-left: 12px;
                            }
                        }
                    }
                }
            }
        }

        &:not(.sub-menu) {
            > .nav-item {
                &:hover {
                    &:not(.nav-category):not(.nav-profile) {
                        > .nav-link {
                            color: $sidebar-dark-menu-hover-color;
                        }
                    }
                }
            }
        }
        &.sub-menu {
            margin-bottom: 0px;
            margin-top: 0;
            list-style: none;

            .nav-item {
                padding: 0;
                .nav-link {
                    color: $sidebar-dark-submenu-color;
                    padding: $sidebar-submenu-item-padding;
                    position: relative;
                    font-size: $sidebar-submenu-font-size;
                    line-height: 1;
                    height: auto;
                    border-top: 0;
                    &:before {
                        content: '\F054';
                        font-family: 'Material Design Icons';
                        display: block;
                        position: absolute;
                        left: 0px;
                        .rtl & {
                            left: auto;
                            right: 0;
                            content: '\F04D';
                        }
                        top: 50%;
                        @include transform(translateY(-50%));
                        color: lighten($sidebar-dark-submenu-color, 10%);
                        font-size: 0.75rem;
                    }
                    &.active {
                        color: $sidebar-dark-menu-active-color;
                        background: transparent;
                        &:before {
                            color: theme-color(client);
                        }
                    }
                    &:hover {
                        color: $sidebar-dark-submenu-hover-color;
                    }
                }
                &:hover {
                    background: transparent;
                }
            }
        }
    }
}

/* style for off-canvas menu*/
// @media screen and (max-width: 991px) {
//   .sidebar-offcanvas {
//     position: fixed;
//     max-height: calc(100vh - #{$navbar-height});
//     top: $navbar-height;
//     bottom: 0;
//     overflow: auto;
//     right: -$sidebar-width-lg;
//     -webkit-transition: all 0.25s ease-out;
//     -o-transition: all 0.25s ease-out;
//     transition: all 0.25s ease-out;
//     &.active {
//       right: 0;
//     }
//   }
// }
