/* Bar loader */
.bar-loader {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}

.bar-loader span {
    display: inline-block;
    width: 5px;
    height: 30px;
    background-color: rgba(theme-color(danger), 0.7);
}

.bar-loader span:nth-child(1) {
    animation: grow 1s ease-in-out infinite;
}

.bar-loader span:nth-child(2) {
    animation: grow 1s ease-in-out 0.15s infinite;
}

.bar-loader span:nth-child(3) {
    animation: grow 1s ease-in-out 0.3s infinite;
}

.bar-loader span:nth-child(4) {
    animation: grow 1s ease-in-out 0.45s infinite;
}

@keyframes grow {
    0%,
    100% {
        -webkit-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
        transform: scaleY(1);
    }

    50% {
        -webkit-transform: scaleY(1.8);
        -ms-transform: scaleY(1.8);
        -o-transform: scaleY(1.8);
        transform: scaleY(1.8);
    }
}
