/* Dropdowns */

.dropdown {
    .dropdown-toggle {
        &:after {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 0;
            font: normal normal normal 24px/1 'Material Design Icons';
            content: '\f140';
            width: auto;
            height: auto;
            vertical-align: baseline;
            font-size: 0.75rem;
        }
    }
    .dropdown-menu {
        margin-top: 0.75rem;
        font-size: $default-font-size;
        box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
        .rtl & {
            text-align: right;
        }
        .dropdown-item {
            font-size: 1rem;
            padding: 0.25rem 1.5rem;
            cursor: pointer;
            .rtl & {
                text-align: right;
            }
            &:active {
                background: initial;
            }
        }
    }
}
