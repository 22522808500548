.footer {
    background: $footer-bg;
    color: $footer-color;
    border-top: 1px solid darken($footer-bg, 5%);
    padding: 1.5rem 3rem 1.5rem 3rem;
    transition: all $action-transition-duration
        $action-transition-timing-function;
    -moz-transition: all $action-transition-duration
        $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration
        $action-transition-timing-function;
    -ms-transition: all $action-transition-duration
        $action-transition-timing-function;
    font-size: calc(#{$default-font-size} - 0.05rem);
    font-family: $type1;
    font-weight: 400;
    a {
        color: $body-color;
        font-size: inherit;
    }
    @media (max-width: 991px) {
        margin-left: 0;
        width: 100%;
    }
}

.logoFooter {
    max-width: 120px;
    margin-left: 71px;
    position: fixed;
    bottom: 6px;
}
