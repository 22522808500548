/* Dot opacity loader */

.dot-opacity-loader {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}

.dot-opacity-loader span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: theme-color(primary);
    margin: 35px 5px;
    opacity: 0;
}

.dot-opacity-loader span:nth-child(1) {
    animation: opacitychange 1s ease-in-out infinite;
}

.dot-opacity-loader span:nth-child(2) {
    animation: opacitychange 1s ease-in-out 0.33s infinite;
}

.dot-opacity-loader span:nth-child(3) {
    animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange {
    0%,
    100% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }
}
