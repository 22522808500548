/* Circle loader */
.circle-loader {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}
.circle-loader:before,
.circle-loader:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 10px solid transparent;
    border-top-color: theme-color(warning);
}

.circle-loader:before {
    z-index: 10;
    animation: spin 1s infinite;
}

.circle-loader:after {
    border: 10px solid theme-color(secondary);
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
